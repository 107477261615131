<template>
  <div class="product container">
    <Topbar />
    <ProductsDetail />
    <Menu />
  </div>
</template>

<script>
import Topbar from '@/components/Topbar.vue'
import ProductsDetail from '@/components/ProductsDetail.vue'
import Menu from '@/components/Menus.vue'
export default {
  components: {
    Topbar,
    ProductsDetail,
    Menu
  }
}
</script>