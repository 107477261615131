<template>
  <div class="downloads">
    <div class="search-content">
      <el-input v-model="search" type="search" placeholder="搜索" @keyup.enter.native="onSearch()">
        <el-button slot="append" icon="el-icon-search" @click="onSearch()"></el-button>
      </el-input>
    </div>
    <div class="downloads-list">
      <ul>
        <li v-for="item in lists" :key="item.Did">
          <img :src="item.DataCoverFilePath" />
          <h2>{{ item.Title }}</h2>
          <h3>{{ item.Code }}</h3>
          <a :href="item.DataFilePath" target="_blank">下载</a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return{
      lists: [],
      openid: this.$route.query.openid,
      code: this.$route.query.code,
      search: this.$route.query.q == undefined ? '' : this.$route.query.q,
      category: ''
    }
  },

  mounted(){
    let that = this
    if(that.openid != null && that.openid != undefined && that.openid != ''){
      that.loadPageOpenid(that.openid)
    }else if(that.code != null && that.code != undefined && that.code != ''){
      that.loadPageCode(that.code)
    }else{
      that.loadPageAuth()
    }
  },

  methods: {

    onSearch: function(){
      let that = this
      if(that.openid != null && that.openid != undefined && that.openid != ''){
        that.loadPageOpenid(that.openid)
      }else if(that.code != null && that.code != undefined && that.code != ''){
        that.loadPageCode(that.code)
      }else{
        that.loadPageAuth()
      }      
    },

    /**
     * Load page with openid
     */
    loadPageOpenid: function(openid){
      let that = this
      let url = 'http://victaulic.simplybrand.com/ajaxdata/Downloadauth.ashx'
      let _query = {
        type: "openid", value: openid, search: that.search, category: that.category
      }
      that.$axios({
        method: 'POST',
        url: url,
        params: _query,
      }).then(function (response) {
        console.log(response)
        let data = response.data
        if (data.retCode == 200) {
          that.lists = response.data.value
        }
        else if (data.retCode == 201) {
          console.log(data.retCode)
        }
        else if (data.retCode == 202) {
          console.log(data.retCode)
        }
        else if (data.retCode == 205) {
          console.log(data.retCode)
          window.location.href = data.value + "&q=" + '' + "&category=" + '';
        }
        else if (data.retCode == 208) {
          console.log(data.retCode)
          window.location.href = data.value + "&q=" + '' + "&category=" + '';
        }        
      }).catch(function (error) {
        console.log(error)
      })
    },

    /**
     * Load page with code
     */
    loadPageCode: function(code){
      let that = this
      let url = 'http://victaulic.simplybrand.com/ajaxdata/Downloadauth.ashx'
      let _query = {
        type: "code", value: code, search: that.search, category: that.category
      }
      that.$axios({
        method: 'POST',
        url: url,
        params: _query,
      }).then(function (response) {
        console.log(response)
        let data = response.data
        if (data.retCode == 200) {
          that.lists = response.data.value
        }
        else if (data.retCode == 201) {
          console.log(data.retCode)
        }
        else if (data.retCode == 202) {
          console.log(data.retCode)
        }
        else if (data.retCode == 205) {
          console.log(data.retCode)
          window.location.href = data.value + "&q=" + '' + "&category=" + '';
        }
        else if (data.retCode == 208) {
          console.log(data.retCode)
          window.location.href = data.value + "&q=" + '' + "&category=" + '';
        }        
      }).catch(function (error) {
        console.log(error)
      })
    },

    /**
     * Load page with auth
     */
    loadPageAuth: function(){
      let that = this
      let url = 'http://victaulic.simplybrand.com/ajaxdata/Downloadauth.ashx'
      let _query = {
        type: "auth", value: "", search: that.search, category: that.category
      }
      that.$axios({
        method: 'POST',
        url: url,
        params: _query,
      }).then(function (response) {
        console.log(response)
        let data = response.data
        if (data.retCode == 200) {
          console.log(data.retCode)
          var dataResult = data.value;
          console.log(dataResult)
        }
        else if (data.retCode == 201) {
          console.log(data.retCode)
        }
        else if (data.retCode == 202) {
          console.log(data.retCode)
        }
        else if (data.retCode == 205) {
          console.log(data.retCode)
          window.location.href = data.value + "&q=" + '' + "&category=" + '';
        }
        else if (data.retCode == 208) {
          console.log(data.retCode)
          window.location.href = data.value + "&q=" + '' + "&category=" + '';
        }        
      }).catch(function (error) {
        console.log(error)
      })
    },

  }
}
</script>