<template>
  <div class="video container">
    <Topbar />
    <VideosCategory />
    <Menu />
  </div>
</template>

<script>
import Topbar from '@/components/Topbar.vue'
import VideosCategory from '@/components/VideosCategory.vue'
import Menu from '@/components/Menus.vue'
export default {
  components: {
    Topbar,
    VideosCategory,
    Menu
  }
}
</script>